import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import Accesorios from '../pages/Accessories/Accesorios'
import AreasDeLaEmpresa from '../pages/Areas/Areas'
import Empleados from '../pages/Employees/Employees'
import Equipos from '../pages/Hardware/Hardware'
import Home from '../pages/Home'
import Inventario from '../pages/Inventario'
import Auth from '../pages/Auth/Auth'
import Mantenimiento from '../pages/Maintenances/Maintenances'
import Brands from '../pages/Brands/Brands'
import TiposDeEquipos from '../pages/HardwareTypes/HardwareTypes'
import TiposDeMantenimiento from '../pages/MaintenanceTypes/MaintenanceTypes'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            name: 'Auth',
            path: '/auth',
            component: Auth
        },
        {
            path: '/',
            redirect: 'home',
            component: Inventario,
            children: [
                { path: 'accesorios', component: Accesorios, name: 'Accesorios' },
                { path: 'areas-de-la-empresa', component: AreasDeLaEmpresa, name: 'Áreas de la empresa' },
                { path: 'empleados', component: Empleados, name: 'Empleados' },
                { path: 'equipos', component: Equipos, name: 'Equipos' },
                { path: 'home', component: Home, name: 'Home' },
                { path: 'mantenimiento', component: Mantenimiento, name: 'Mantenimiento' },
                { path: 'marcas', component: Brands, name: 'Brands' },
                { path: 'tipos-de-equipos', component: TiposDeEquipos, name: 'Tipos de equipos' },
                { path: 'tipos-de-mantenimiento', component: TiposDeMantenimiento, name: 'Tipos de mantenimiento' }
            ]
        }
    ],
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    if (to.name !== 'Auth' && !store.getters.isLoggedIn) next({ name: 'Auth' })
    else next()
})

export default router