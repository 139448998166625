import auth from '@/store/modules/auth'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: { auth },
    plugins: [createPersistedState({
        paths: ['auth']
    })]
})

export default store