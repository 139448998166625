<template>
  <v-dialog @input="close"
            max-width="800px"
            scrollable
            :value="value">
    <v-card>
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          Mantenimiento
        </v-toolbar-title>

        <v-spacer/>

        <v-btn @click="close" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text v-if="loading">
        <v-progress-circular indeterminate size="30"></v-progress-circular>
      </v-card-text>

      <v-card-text v-if="value && maintenance">
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="accent">
                mdi-tools
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ maintenance.madeBy ? `${maintenance.madeBy.names} ${maintenance.madeBy.surnames}` : 'No tiene un responsable asignado' }}
              </v-list-item-title>
              <v-list-item-subtitle>Realizado por</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="accent">
                mdi-account-alert
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ maintenance.requestedBy ? `${maintenance.requestedBy.names} ${maintenance.requestedBy.names}` : 'No tiene un solicitante asignado' }}
              </v-list-item-title>
              <v-list-item-subtitle>Solicitado por</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="accent">
                mdi-wrench
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ maintenance.maintenanceType.name }}
              </v-list-item-title>
              <v-list-item-subtitle>Tipo de mantenimiento</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="maintenance.dateStarts">
            <v-list-item-icon>
              <v-icon color="accent">
                mdi-calendar-clock
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ maintenance.dateStarts }}
              </v-list-item-title>
              <v-list-item-subtitle>Inicio</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="maintenance.dateEnds">
            <v-list-item-icon>
              <v-icon color="accent">
                mdi-calendar-check
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ maintenance.dateEnds }}
              </v-list-item-title>
              <v-list-item-subtitle>Finalización</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="maintenance.newResponsible">
            <v-list-item-icon>
              <v-icon color="accent">
                mdi-account-plus
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ `${maintenance.newResponsible.names} ${maintenance.newResponsible.surnames}` }}
              </v-list-item-title>
              <v-list-item-subtitle>Nuevo responsable</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider/>

          <v-subheader v-if="showHardwareSection">
            <v-icon left @click="showHardware = !showHardware">
              mdi-eye
            </v-icon>
            Equipo
          </v-subheader>

          <v-expand-transition v-if="showHardwareSection">
            <div v-show="showHardware">
              <HardwareInfo :selectedHardware="maintenance.hardware" />
            </div>
          </v-expand-transition>

          <v-divider/>

          <v-subheader>Motivo</v-subheader>

          <v-col cols="12" class="caption px-4">
            {{selectedMaintenance.reason}}
          </v-col>

          <v-divider/>

          <v-subheader>Accesorios implicados</v-subheader>

          <v-col cols="12" v-if="selectedMaintenance.accessories.length === 0">
            <v-alert type="warning">No hay ningún accesorio registrado</v-alert>
          </v-col>

          <v-col cols="12" v-else>
            <AccessoryItem :accessory="item"
                           :key="item._id"
                           v-for="item in selectedMaintenance.accessories">
            </AccessoryItem>
          </v-col>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn @click="close" text>
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccessoryItem from '@/components/AccessoryItem'
import HardwareInfo from '@/pages/Hardware/HardwareInfo'
import maintenancesService from '@/services/maintenancesService'

export default {
  components: {
    AccessoryItem,
    HardwareInfo
  },
  data: () => ({
    loading: false,
    maintenance: null,
    showHardware: false
  }),
  methods: {
    close () {
      this.$emit('close')
    },
    async getMaintenanceById (id) {
      try {
        this.loading = true
        const response = await maintenancesService.getMaintenanceById(id)
        this.maintenance = response.maintenance
        this.loading = false
      } catch (e) {
        this.$snotify.error(e.message)
      }
    }
  },
  props: ['selectedMaintenance', 'value', 'showHardwareSection'],
  watch: {
    selectedMaintenance (value) {
      if (!value) return

      this.getMaintenanceById(value._id)
    }
  }
}
</script>