<template>
  <div>
    <v-app>
      <div class="center-container">
        <div class="login-container">
          <v-container>
            <v-row justify="center" no-gutters class="mb-5">
              <v-img max-width="150px" src="https://inventario.jua.com.co/logo.png"></v-img>
            </v-row>

            <Login/>
          </v-container>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import Login from '@/pages/Auth/Login.vue'
export default {
  components: { Login }
}
</script>

<style>
.card-jua {
  border-radius: 20px !important;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #161916;
  height: 100%;
}

.login-container {
  width: 100%;
  max-width: 600px;
}
</style>