<template>
  <v-container>
    <DeleteDialog @cancel="cancelDelete"
                  @delete="deleteItem"
                  :loading="loadingDelete"
                  v-model="showDeleteDialog">
    </DeleteDialog>

    <NewEditComputer @close="closeNewEditHardware"
                     :selectedHardware="selectedHardwareToEdit"
                     @update="listHardware"
                     v-model="showNewEditHardware">
    </NewEditComputer>

    <ViewHardware @close="closeViewHardware"
                  :selectedHardware="selectedHardware"
                  v-model="viewHardware">
    </ViewHardware>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field append-icon="mdi-magnify"
                          filled
                          label="Buscar equipo"
                          :loading="loading"
                          v-model="search">
              <template #append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="showNewEditHardware = true"
                            color="primary"
                            v-bind="attrs"
                            v-on="on">
                      mdi-plus
                    </v-icon>
                  </template>

                  <span>Agregar equipo</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </v-card-title>

          <v-data-table :headers="headers"
                        :items="filteredHardware"
                        :items-per-page="10">
            <template v-slot:item.brand="{ item }">
              {{ item.brand[0].name }}
            </template>

            <template v-slot:item.equipmentType="{ item }">
              {{ item.equipmentType[0].name }}
            </template>

            <template v-slot:item.assignee="{ item }">
              {{ item.assignee ? `${item.assignee.names} ${item.assignee.surnames}` : '' }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon @click="viewItem(item)">
                mdi-eye
              </v-icon>

              <v-icon class="ml-2"
                      @click="updateItem(item)">
                mdi-pencil
              </v-icon>

              <v-icon class="ml-2"
                      @click="openDeleteDialog(item)"
                      color="red">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import hardwareService from '@/services/hardwareService'
import NewEditComputer from './NewEditHardware.vue'
import ViewHardware from './ViewHardwareDialog'

export default {
  components: { DeleteDialog, NewEditComputer, ViewHardware },
  computed: {
    filteredHardware () {
      if (!this.search) return this.hardware

      const search = this.search.toLowerCase()

      const filteredHardware = this.hardware.filter(hardware => {
        const plaque = hardware.plaque && hardware.plaque.toLowerCase().includes(search)
        const serial = hardware.serial && hardware.serial.toLowerCase().includes(search)
        const area = hardware.assignee && hardware.assignee.area && hardware.assignee.area.name.toLowerCase().includes(search)
        const assignee = hardware.assignee && hardware.assignee.names.toLowerCase().includes(search)

        return plaque || serial || area || assignee
      })

      return filteredHardware
    }
  },
  data () {
    return {
      hardware: [],
      headers: [
        { value: 'actions', align: 'center' },
        { text: 'Placa', align: 'start', sortable: false, value: 'plaque' },
        { text: 'Serial', value: 'serial' },
        { text: 'Área', value: 'assignee.area.name' },
        { text: 'Marca', value: 'brand.name' },
        { text: 'Modelo', value: 'model' },
        { text: 'Tipo de equipo', value: 'hardwareType.name' },
        { text: 'Responsable asignado', value: 'assignee' },
      ],
      loading: false,
      loadingDelete: false,
      search: '',
      selectedHardwareToEdit: null,
      selectedHardware: null,
      showDeleteDialog: false,
      showNewEditHardware: false,
      viewHardware: false
    }
  },
  methods: {
    cancelDelete () {
      this.showDeleteDialog = false
      this.selectedHardwareToDelete = null
    },
    closeNewEditHardware () {
      this.selectedHardwareToEdit = null
      this.showNewEditHardware = false
    },
    closeViewHardware () {
      this.selectedHardware = null
      this.viewHardware = false
    },
    async deleteItem () {
      try {
        this.loadingDelete = true
        await hardwareService.remove(this.selectedHardwareToDelete._id)
        await this.listHardware()
        this.selectedHardwareToDelete = null
        this.showDeleteDialog = false
        this.loadingDelete = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingDelete = false
      }
    },
    async listHardware () {
      try {
        this.loading = true
        const response = await hardwareService.list()
        this.hardware = response.hardware
        this.loading = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.hardware = []
        this.loading = false
      }
    },
    openDeleteDialog (item) {
      this.selectedHardwareToDelete = item
      this.showDeleteDialog = true
    },
    updateItem (item) {
      this.selectedHardwareToEdit = item
      this.showNewEditHardware = true
    },
    async viewItem (item) {
      const response = await hardwareService.getById(item._id)
      this.selectedHardware = response.hardware
      this.viewHardware = true
    }
  },
  mounted () {
    this.listHardware()
  }
}
</script>