<template>
  <div>
    <router-view/>
    <vue-snotify/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    }
  },
  watch: {
    isLoggedIn (value) {
      this.$router.push(value ? '/home' : '/auth')
    }
  }
};
</script>
