var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ReportMaintenance',{on:{"update":_vm.listMaintenances},model:{value:(_vm.showReportMaintenance),callback:function ($$v) {_vm.showReportMaintenance=$$v},expression:"showReportMaintenance"}}),_c('ViewMaintenance',{attrs:{"selectedMaintenance":_vm.selectedItemToView,"showHardwareSection":true},on:{"close":_vm.closeViewMaintenance},model:{value:(_vm.viewMaintenance),callback:function ($$v) {_vm.viewMaintenance=$$v},expression:"viewMaintenance"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"filled":"","label":"Buscar mantenimiento","append-icon":"mdi-magnify"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.showReportMaintenance = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v("Reportar mantenimiento")])])]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.maintenances,"items-per-page":10},scopedSlots:_vm._u([{key:"item.serial",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.hardware[0].serial))])]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.assignee[0].firstName)+" "+_vm._s(item.assignee[0].lastName))])]}},{key:"item.maintenanceType",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.maintenanceType[0].name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-eye ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }