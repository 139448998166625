import axios from 'axios'

const auth = {}

auth.login = async data => {
    const { email, password } = data

    const request = {
        method: 'post',
        url: '/auth/login',
        data: { email, password }
    }

    const response = await axios(request)
    return response.data
}

export default auth