import axios from 'axios'

const areasService = {}

areasService.create = async name => {
    if (!name) return

    const request = {
        method: 'post',
        url: '/areas',
        data: { name }
    }

    const response = await axios(request)
    return response.data
}

areasService.remove = async id => {
    if (!id) return

    const request = {
        method: 'delete',
        url: `/areas/${id}`
    }

    const response = await axios(request)
    return response.data
}

areasService.list = async () => {
    const request = {
        method: 'get',
        url: '/areas',
    }

    const response = await axios(request)
    return response.data
}

areasService.update = async data => {
    const { id, name } = data

    const request = {
        method: 'put',
        url: `/areas/${id}`,
        data: { name }
    }

    const response = await axios(request)
    return response.data
}

export default areasService