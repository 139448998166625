<template>
  <v-dialog max-width="1000px"
            :value="value"
            scrollable
            @input="$emit('input', $event)">
    <v-card>
      <v-toolbar color="#F56552" dark flat>
        <v-toolbar-title>
          Reportar mantenimiento
        </v-toolbar-title>

        <v-spacer/>

        <v-btn @click="close" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-form lazy-validation
                ref="formNewEditMaintenance"
                v-model="valid">
          <v-row class="mt-4">
            <v-col cols="12" sm="6">
              <v-autocomplete :filter="filterHardware"
                              hint="Escriba la placa del equipo"
                              :items="hardware"
                              item-value="_id"
                              label="Equipo"
                              outlined
                              persistent-hint
                              v-model="maintenance.hardware">
                <template v-slot:selection="data">
                  {{ data.item.plaque }}
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.plaque || 'Sin placa'"></v-list-item-title>
                      <v-list-item-subtitle v-if="data.item.assignee">
                        {{`${data.item.assignee.names} ${data.item.assignee.surnames}`}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-else>Sin responsable asignado</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-chip>{{ data.item.assignee.area.name}}</v-chip>
                    </v-list-item-icon>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="maintenanceTypes"
                              item-text="name"
                              item-value="_id"
                              label="Tipo de mantenimiento"
                              outlined
                              persistent-hint
                              v-model="maintenance.maintenanceType">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-dialog ref="dialogFechaInicio"
                        v-model="modalFechaInicio"
                        :return-value.sync="maintenance.dateStarts"
                        persistent
                        width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Fecha de inicio"
                                outlined
                                readonly
                                v-bind="attrs"
                                v-model="maintenance.dateStarts"
                                v-on="on">
                  </v-text-field>
                </template>

                <v-date-picker v-model="maintenance.dateStarts"
                               scrollable>
                  <v-spacer/>

                  <v-btn text
                         color="red"
                         @click="modalFechaInicio = false">
                    Cancelar
                  </v-btn>

                  <v-btn text
                         color="primary"
                         @click="$refs.dialogFechaInicio.save(maintenance.dateStarts)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6">
              <v-dialog ref="dialogHourStart"
                        v-model="modalHoraInicio"
                        :return-value.sync="maintenance.hourStarts"
                        persistent
                        width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Hora de inicio"
                                outlined
                                readonly
                                v-bind="attrs"
                                v-model="maintenance.hourStarts"
                                v-on="on">
                  </v-text-field>
                </template>

                <v-time-picker v-if="modalHoraInicio"
                               v-model="maintenance.hourStarts"
                               full-width>
                  <v-spacer/>
                  <v-btn text
                         color="red"
                         @click="modalHoraInicio = false">
                    Cancelar
                  </v-btn>

                  <v-btn text
                         color="primary"
                         @click="$refs.dialogHourStart.save(maintenance.hourStarts)">
                    Aceptar
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6">
              <v-dialog ref="dialogFechaFin"
                        v-model="modalFechaFin"
                        :return-value.sync="maintenance.dateEnds"
                        persistent
                        width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Fecha de finalización"
                                outlined
                                readonly
                                v-bind="attrs"
                                v-model="maintenance.dateEnds"
                                v-on="on">
                  </v-text-field>
                </template>

                <v-date-picker v-model="maintenance.dateEnds"
                               scrollable>
                  <v-spacer/>

                  <v-btn text
                         color="red"
                         @click="modalFechaFin = false">
                    Cancelar
                  </v-btn>

                  <v-btn text
                         color="primary"
                         @click="$refs.dialogFechaFin.save(maintenance.dateEnds)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6">
              <v-dialog ref="dialogHourEnd"
                        v-model="modalHoraFin"
                        :return-value.sync="maintenance.hourEnds"
                        persistent
                        width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Hora de finalización"
                                outlined
                                readonly
                                v-bind="attrs"
                                v-model="maintenance.hourEnds"
                                v-on="on">
                  </v-text-field>
                </template>

                <v-time-picker v-if="modalHoraFin"
                               v-model="maintenance.hourEnds"
                               full-width>
                  <v-spacer/>
                  <v-btn text
                         color="red"
                         @click="modalHoraFin = false">
                    Cancelar
                  </v-btn>

                  <v-btn text
                         color="primary"
                         @click="$refs.dialogHourEnd.save(maintenance.hourEnds)">
                    Aceptar
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="employees"
                              item-text="names"
                              item-value="_id"
                              label="Realizado por"
                              outlined
                              persistent-hint
                              v-model="maintenance.madeBy">
                <template v-slot:selection="data">
                  {{ `${data.item.names} ${data.item.surnames}` }}
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="`${data.item.names} ${data.item.surnames}`"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="employees"
                              item-text="names"
                              item-value="_id"
                              label="Solicitado por"
                              outlined
                              persistent-hint
                              v-model="maintenance.requestedBy">
                <template v-slot:selection="data">
                  {{ `${data.item.names} ${data.item.surnames}` }}
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="`${data.item.names} ${data.item.surnames}`"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="employees"
                              item-text="names"
                              item-value="_id"
                              label="Nuevo responsable"
                              outlined
                              persistent-hint
                              v-model="maintenance.newResponsible">
                <template v-slot:selection="data">
                  {{ `${data.item.names} ${data.item.surnames}` }}
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="`${data.item.names} ${data.item.surnames}`"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-textarea label="Motivo del mantenimiento"
                          outlined
                          v-model="maintenance.reason"
                          :rules="[rules.required]">
              </v-textarea>
            </v-col>

            <v-col cols="12">
              <v-card-title class="pa-0">
                Accesorios implicados
              </v-card-title>
            </v-col>

            <AccessoriesItems v-model="maintenance.accessories"/>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn @click="close"
               color="red"
               text>
          Cancelar
        </v-btn>

        <v-btn @click="submit"
               color="accent"
               dark
               :loading="loadingCreate">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccessoriesItems from '@/components/AccessoriesItems'
import maintenancesService from '@/services/maintenancesService'
import employeesService from '@/services/employeesService'
import hardwareService from '@/services/hardwareService'
import maintenanceTypesService from '@/services/maintenanceTypesService'
import rules from '@/libs/rules'

export default {
  components: { AccessoriesItems },
  data: () => ({
    employees: [],
    hardware: [],
    loadingCreate: false,
    loadingEmployees: false,
    loadingHardware: false,
    loadingMaintenanceTypes: false,
    maintenance: {
      hardware: null,
      maintenanceType: null,
      dateStarts: null,
      dateEnds: null,
      hourStarts: null,
      hourEnds: null,
      madeBy: null,
      requestedBy: null,
      newResponsible: null,
      reason: null,
      accessories: [],
    },
    maintenanceTypes: [],
    modalFechaInicio: false,
    modalFechaFin: false,
    modalHoraInicio: false,
    modalHoraFin: false,
    rules,
    valid: true
  }),
  methods: {
    close () {
      this.maintenance.accessories = []
      this.$refs.formNewEditMaintenance.reset()
      this.$emit('input', false)
    },
    filterHardware (item, queryText) {
      const searchText = queryText.toLowerCase()
      return item.plaque.includes(searchText) || item.assignee.names.toLowerCase().includes(searchText) || item.assignee.surnames.toLowerCase().includes(searchText)
    },
    async listEmployees () {
      try {
        this.loadingEmployees = true
        const response = await employeesService.list()
        this.employees = response.employees
        this.loadingEmployees = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingEmployees = false
      }
    },
    async listHardware () {
      try {
        this.loadingHardware = true
        const response = await hardwareService.list()
        this.hardware = response.hardware
        this.loadingHardware = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.hardware = []
        this.loadingHardware = false
      }
    },
    async listMaintenanceTypes () {
      try {
        this.loadingMaintenanceTypes = true
        const response = await maintenanceTypesService.list()
        this.maintenanceTypes = response.maintenanceTypes
        this.loadingMaintenanceTypes = false
      } catch (e) {
        this.maintenanceTypes = []
        this.$snotify.error(e.message)
        this.loadingMaintenanceTypes = false
      }
    },
    async submit () {
      try {
        if (!this.$refs.formNewEditMaintenance.validate()) {

          return this.$snotify.error('Describe el motivo del mantenimiento');
        }

        this.loadingCreate = true

        const dateStarts = new Date(`${this.maintenance.dateStarts}T${this.maintenance.hourStarts || '00:00'}`)
        const dateEnds = new Date(`${this.maintenance.dateEnds}T${this.maintenance.hourEnds || '00:00'}`)

        const data = {
          hardware: this.maintenance.hardware,
          maintenanceType: this.maintenance.maintenanceType,
          dateStarts: dateStarts,
          dateEnds: dateEnds,
          madeBy: this.maintenance.madeBy,
          requestedBy: this.maintenance.requestedBy,
          newResponsible: this.maintenance.newResponsible,
          reason: this.maintenance.reason,
          accessories: this.maintenance.accessories
        }

        await maintenancesService.create(data)

        this.$emit('update')
        this.loadingCreate = false
        this.close()
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingCreate = false
      }
    }
  },
  mounted () {
    this.listEmployees()
    this.listHardware()
    this.listMaintenanceTypes()
  },
  props: ['value']
}
</script>