<template>
  <v-list color="grey lighten-4" style="border-radius: 8px">
    <v-list-item v-if="accessory.name">
      <v-list-item-icon>
        <v-icon color="accent">
          mdi-form-textbox
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ accessory.name }}</v-list-item-title>
        <v-list-item-subtitle>Nombre del accesorio</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="accessory.brand">
      <v-list-item-icon>
        <v-icon color="accent">
          mdi-registered-trademark
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ accessory.brand }}</v-list-item-title>
        <v-list-item-subtitle>Marca</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="accessory.model">
      <v-list-item-icon>
        <v-icon color="accent">
          mdi-alpha-a-box
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ accessory.model }}</v-list-item-title>
        <v-list-item-subtitle>Modelo</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="accessory.serial">
      <v-list-item-icon>
        <v-icon color="accent">
          mdi-barcode
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ accessory.serial }}</v-list-item-title>
        <v-list-item-subtitle>Serial</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="accessory.capacity">
      <v-list-item-icon>
        <v-icon color="accent">
          mdi-harddisk
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ accessory.capacity }}</v-list-item-title>
        <v-list-item-subtitle>Capacidad</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="accessory.speed">
      <v-list-item-icon>
        <v-icon color="accent">
          mdi-speedometer
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ accessory.speed }}</v-list-item-title>
        <v-list-item-subtitle>Velocidad</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="accessory.purchaseDate">
      <v-list-item-icon>
        <v-icon color="accent">
          mdi-calendar
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ accessory.purchaseDate }}</v-list-item-title>
        <v-list-item-subtitle>Fecha de compra</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-subheader v-if="accessory.notes">Notas</v-subheader>

    <v-col cols="12" class="caption px-4" v-if="accessory.notes">
      {{ accessory.notes }}
    </v-col>
  </v-list>
</template>

<script>
export default {
  props: ['accessory']
}
</script>