import axios from 'axios'

const maintenancesService = {}

maintenancesService.create = async data => {
    let {
        hardware,
        maintenanceType,
        dateStarts,
        dateEnds,
        madeBy,
        requestedBy,
        newResponsible,
        reason,
        accessories
    } = data

    const request = {
        method: 'post',
        url: '/maintenances',
        data: {
            hardware,
            maintenanceType,
            dateStarts,
            dateEnds,
            madeBy,
            requestedBy,
            newResponsible,
            reason,
            accessories
        }
    }

    const response = await axios(request)
    return response.data
}

maintenancesService.remove = async id => {
    if (!id) return

    const request = {
        method: 'delete',
        url: `/maintenances/${id}`
    }

    const response = await axios(request)
    return response.data
}

maintenancesService.list = async () => {
    const request = {
        method: 'get',
        url: '/maintenances',
    }

    const response = await axios(request)
    return response.data
}

maintenancesService.getMaintenanceById = async id => {
    const request = {
        method: 'get',
        url: `/maintenances/${id}`,
    }

    const response = await axios(request)
    return response.data
}

maintenancesService.update = async data => {
    const { id, name } = data

    const request = {
        method: 'put',
        url: `/maintenances/${id}`,
        data: { name }
    }

    const response = await axios(request)
    return response.data
}

export default maintenancesService