<template>
  <v-dialog @input="close"
            max-width="800px"
            scrollable
            :value="value"
            eager>
    <ViewMaintenance @close="closeMaintenanceDialog"
                     :selected-maintenance="selectedMaintenance"
                     v-model="showMaintenanceDialog">
    </ViewMaintenance>

    <v-card>
      <v-toolbar class="elevation-0">
        <v-toolbar-title>
          Equipo
        </v-toolbar-title>

        <v-spacer/>

        <v-btn @click="close" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <HardwareInfo :selectedHardware="selectedHardware" />
          </v-col>

          <v-col cols="12" v-if="selectedHardware">
            <v-card-text>
              <v-card-title>Mantenimientos</v-card-title>

              <v-divider/>

              <v-list>
                <v-list-item v-for="item in selectedHardware.maintenances" :key="item._id">
                  <v-list-item-action>
                    <v-icon @click="showMaintenance(item)">mdi-eye</v-icon>
                  </v-list-item-action>
                  {{item.reason || 'Sin motivo definido'}}
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn @click="close" text>
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import HardwareInfo from '@/pages/Hardware/HardwareInfo'
import ViewMaintenance from '@/pages/Maintenances/ViewMaintenance'

export default {
  components: {
    HardwareInfo,
    ViewMaintenance,
  },
  data: () => ({
    selectedMaintenance: null,
    showMaintenanceDialog: false,
  }),
  methods: {
    close () {
      this.$emit('close')
    },
    closeMaintenanceDialog () {
      this.showMaintenanceDialog = false
      this.selectedMaintenance = null
    },
    showMaintenance (item) {
      this.selectedMaintenance = item
      this.showMaintenanceDialog = true
    }
  },
  props: ['selectedHardware', 'value']
}
</script>