<template>
  <v-dialog @input="close"
            max-width="1000px"
            persistent
            scrollable
            :value="value">
    <v-card>
      <v-toolbar color="accent" dark flat>
        <v-toolbar-title>
          {{ selectedHardware ? 'Actualizar equipo' : 'Crear nuevo equipo' }}

        </v-toolbar-title>

        <v-spacer/>

        <v-btn @click="close" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-4">
        <v-form lazy-validation
                ref="newEditHardwareForm"
                v-model="valid">
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field label="Placa"
                            outlined
                            v-model="hardware.plaque">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field label="Serial"
                            outlined
                            v-model="hardware.serial">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="hardwareTypes"
                              item-text="name"
                              item-value="_id"
                              label="Tipo de equipo"
                              :loading="loadingEquipmentTypes"
                              outlined
                              v-model="hardware.hardwareType">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="brands"
                              item-text="name"
                              item-value="_id"
                              label="Marca"
                              :loading="loadingBrands"
                              outlined
                              v-model="hardware.brand">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field label="Modelo"
                            outlined
                            v-model="hardware.model">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field label="Procesador"
                            outlined
                            v-model="hardware.cpu">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field label="Memoria RAM"
                            outlined
                            v-model="hardware.memory">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field label="Disco duro"
                            outlined
                            v-model="hardware.hdd">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="employees"
                              item-text="names"
                              item-value="_id"
                              label="Responsable asignado"
                              :loading="loadingEmployees"
                              outlined
                              v-model="hardware.assignee">
                <template v-slot:selection="data">
                  {{ `${data.item.names} ${data.item.surnames}` }}
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="`${data.item.names} ${data.item.surnames}`"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="employees"
                              item-text="names"
                              item-value="_id"
                              label="Responsable de compra"
                              :loading="loadingEmployees"
                              outlined
                              v-model="hardware.buyer">
                <template v-slot:selection="data">
                  {{ `${data.item.names} ${data.item.surnames}` }}
                </template>

                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title v-html="`${data.item.names} ${data.item.surnames}`"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field label="Costo de compra"
                            outlined
                            v-model.lazy="hardware.cost"
                            v-money="money">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field label="Proveedor"
                            outlined
                            v-model="hardware.provider">
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-dialog ref="dialog"
                        v-model="modalFechaDeCompra"
                        :return-value.sync="hardware.purchaseDate"
                        persistent
                        width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Fecha de compra"
                                outlined
                                readonly
                                v-bind="attrs"
                                v-model="hardware.purchaseDate"
                                v-on="on">
                  </v-text-field>
                </template>

                <v-date-picker v-model="hardware.purchaseDate"
                               scrollable>
                  <v-spacer/>

                  <v-btn text
                         color="red"
                         @click="modalFechaDeCompra = false">
                    Cancelar
                  </v-btn>

                  <v-btn text
                         color="primary"
                         @click="$refs.dialog.save(hardware.purchaseDate)">
                    Aceptar
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete :items="damagedItems"
                              label="Dado de baja"
                              outlined
                              v-model="hardware.damaged">
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-textarea label="Observaciones"
                          outlined
                          v-model="hardware.observations">
              </v-textarea>
            </v-col>

            <AccessoriesItems v-model="hardware.accessories"/>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn @click="close"
               color="red"
               text>
          Cancelar
        </v-btn>

        <v-btn @click="submit"
               dark
               :loading="loading">
          {{ selectedHardware ? 'Actualizar' : 'Crear' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AccessoriesItems from '@/components/AccessoriesItems'
import brandsService from '@/services/brandsService'
import employeesService from '@/services/employeesService'
import hardwareTypesService from '@/services/hardwareTypesService'
import hardwareService from '@/services/hardwareService'
import { VMoney } from 'v-money'
import { CleanStringMoney } from '@/libs/utils'
import rules from '@/libs/rules'

export default {
  components: { AccessoriesItems },
  created() {
    this.listBrands()
    this.listEmployees()
    this.listEquipmentTypes()
  },
  data: () => ({
    brands: [],
    damagedItems: [{ text: 'No', value: false}, {text: 'Si', value: true }],
    employees: [],
    hardwareTypes: [],
    hardware: {
      accessories: [],
      plaque: undefined,
      serial: undefined,
      hardwareType: undefined,
      brand: undefined,
      model: undefined,
      cpu: undefined,
      memory: undefined,
      hdd: undefined,
      assignee: undefined,
      buyer: undefined,
      cost: undefined,
      provider: undefined,
      purchaseDate: undefined,
      damaged: false,
      observations: undefined,
    },
    loading: false,
    loadingBrands: false,
    loadingEmployees: false,
    loadingEquipmentTypes: false,
    modalFechaDeCompra: false,
    money: {
      decimal: ',',
      thousands: '.',
      prefix: 'COP $',
      precision: 0
    },
    rules,
    valid: true
  }),
  directives: { money: VMoney },
  methods: {
    close () {
      this.hardware.accessories = []
      this.$refs.newEditHardwareForm.reset()
      this.$emit('close')
    },
    async create () {
      const data = {
        plaque: this.hardware.plaque,
        serial: this.hardware.serial,
        hardwareType: this.hardware.hardwareType,
        brand: this.hardware.brand,
        model: this.hardware.model,
        cpu: this.hardware.cpu,
        memory: this.hardware.memory,
        hdd: this.hardware.hdd,
        assignee: this.hardware.assignee,
        buyer: this.hardware.buyer,
        cost: CleanStringMoney(this.hardware.cost),
        provider: this.hardware.provider,
        purchaseDate: this.hardware.purchaseDate,
        damaged: this.hardware.damaged,
        observations: this.hardware.observations,
        accessories: this.hardware.accessories,
      }

      await hardwareService.create(data)
    },
    async listBrands () {
      try {
        this.loadingBrands = true
        const response = await brandsService.list()
        this.brands = response.brands
        this.loadingBrands = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingBrands = false
        this.brands = []
      }
    },
    async listEmployees () {
      try {
        this.loadingEmployees = true
        const response = await employeesService.list()
        this.employees = response.employees
        this.loadingEmployees = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingEmployees = false
        this.employees = []
      }
    },
    async listEquipmentTypes () {
      try {
        this.loadingEquipmentTypes = true
        const response = await hardwareTypesService.list()
        this.hardwareTypes = response.hardwareTypes
        this.loadingEquipmentTypes = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingEquipmentTypes = false
        this.hardwareTypes = []
      }
    },
    async submit () {
      try {
        this.loading = true

        this.selectedHardware ? await this.update() : await this.create()

        this.$emit('update')

        this.loading = false
        this.close()
      } catch (e) {
        if (e.response && e.response.data) {
          this.$snotify.error(e.response.data.message)
        } else {
          this.$snotify.error(e.message)
        }
        this.loading = false
      }
    },
    async update () {
      const data = {
        id: this.selectedHardware._id,
        plaque: this.hardware.plaque,
        serial: this.hardware.serial,
        hardwareType: this.hardware.hardwareType,
        brand: this.hardware.brand,
        model: this.hardware.model,
        cpu: this.hardware.cpu,
        memory: this.hardware.memory,
        hdd: this.hardware.hdd,
        assignee: this.hardware.assignee,
        buyer: this.hardware.buyer,
        cost: CleanStringMoney(this.hardware.cost),
        provider: this.hardware.provider,
        purchaseDate: this.hardware.purchaseDate,
        damaged: this.hardware.damaged,
        observations: this.hardware.observations,
        accessories: this.hardware.accessories,
      }

      await hardwareService.update(data)
    },
  },
  props: ['selectedHardware', 'value'],
  watch: {
    selectedHardware (value) {
      if (value) {
        this.hardware = {
          plaque: value.plaque,
          serial: value.serial,
          hardwareType: value.hardwareType ? value.hardwareType._id : null,
          brand: value.brand ? value.brand._id : null,
          model: value.model,
          cpu: value.cpu,
          memory: value.memory,
          hdd: value.hdd,
          assignee: value.assignee ? value.assignee._id : null,
          buyer: value.buyer ? value.buyer._id : null,
          cost: value.cost,
          provider: value.provider,
          purchaseDate: value.purchaseDate ? value.purchaseDate.substring(0, 10) : null,
          damaged: value.damaged,
          observations: value.observations,
          accessories: value.accessories,
        }
      }
    }
  }
}
</script>