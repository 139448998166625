import Vue from 'vue'
import App from './App.vue'
import money from 'v-money'
import router from './router'
import Snotify from 'vue-snotify'
import vuetify from './plugins/vuetify'
import store from '@/store'
import '@/services/configAxios'
import '@/styles/styles.scss'

Vue.config.productionTip = false

Vue.use(money)
Vue.use(Snotify)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
