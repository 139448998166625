<template>
  <v-card class="card-jua">
    <v-card-text>
      <v-form lazy-validation
              ref="loginForm"
              v-model="valid">
        <v-row dense>
          <v-col cols="12">
            <v-text-field @keypress.enter="submit"
                          label="Correo"
                          :rules="[rules.required]"
                          v-model="email">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field @keypress.enter="submit"
                          label="Contraseña"
                          :rules="[rules.required]"
                          type="password"
                          v-model="password">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn block
                   @click="submit"
                   color="secondary"
                   :loading="loading"
                   rounded>
              Ingresar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import authService from '@/services/authService'
import rules from '@/libs/rules'

export default {
  data: () => ({
    email: '',
    loading: false,
    password: '',
    rules,
    valid: true
  }),
  methods: {
    async submit () {
      try {
        if (!this.$refs.loginForm.validate() || this.loading) return

        this.loading = true

        const data = {
          email: this.email,
          password: this.password
        }

        const response = await authService.login(data)

        this.$store.commit('SET_TOKEN', response.token)

        this.loading = false
      } catch (e) {
        this.$snotify.error(e.response.data.message)
        this.loading = false
      }
    }
  }
}
</script>