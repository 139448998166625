import axios from 'axios'

const employeesService = {}

employeesService.create = async data => {
    let { names, surnames, area, position } = data

    if (!names || !surnames) return

    const request = {
        method: 'post',
        url: '/employees',
        data: { names, surnames, area, position }
    }

    const response = await axios(request)
    return response.data
}

employeesService.remove = async id => {
    if (!id) return

    const request = {
        method: 'delete',
        url: `/employees/${id}`
    }

    const response = await axios(request)
    return response.data
}

employeesService.list = async () => {
    const request = {
        method: 'get',
        url: '/employees',
    }

    const response = await axios(request)
    return response.data
}

employeesService.update = async data => {
    let { id, names, surnames, area, position } = data

    if (!names || !surnames) return

    const request = {
        method: 'put',
        url: `/employees/${id}`,
        data: { names, surnames, area, position }
    }

    const response = await axios(request)
    return response.data
}

export default employeesService