var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('DeleteDialog',{attrs:{"loading":_vm.loadingDelete},on:{"cancel":_vm.cancelDelete,"delete":_vm.deleteItem},model:{value:(_vm.showDeleteDialog),callback:function ($$v) {_vm.showDeleteDialog=$$v},expression:"showDeleteDialog"}}),_c('NewEditComputer',{attrs:{"selectedHardware":_vm.selectedHardwareToEdit},on:{"close":_vm.closeNewEditHardware,"update":_vm.listHardware},model:{value:(_vm.showNewEditHardware),callback:function ($$v) {_vm.showNewEditHardware=$$v},expression:"showNewEditHardware"}}),_c('ViewHardware',{attrs:{"selectedHardware":_vm.selectedHardware},on:{"close":_vm.closeViewHardware},model:{value:(_vm.viewHardware),callback:function ($$v) {_vm.viewHardware=$$v},expression:"viewHardware"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","filled":"","label":"Buscar equipo","loading":_vm.loading},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.showNewEditHardware = true}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}])},[_c('span',[_vm._v("Agregar equipo")])])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredHardware,"items-per-page":10},scopedSlots:_vm._u([{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.brand[0].name)+" ")]}},{key:"item.equipmentType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.equipmentType[0].name)+" ")]}},{key:"item.assignee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignee ? ((item.assignee.names) + " " + (item.assignee.surnames)) : '')+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-eye ")]),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){return _vm.updateItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }