<template>
  <v-card-text>
    <v-row class="my-6" justify="center" v-if="loading">
      <v-progress-circular color="primary"
                           indeterminate>
      </v-progress-circular>
    </v-row>

    <v-row v-if="!loading && hardware">
      <v-list>
        <!--          Tipo de equipo-->
        <v-list-item v-if="hardware.hardwareType">
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-selection-search
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.hardwareType.name }}</v-list-item-title>
            <v-list-item-subtitle>Tipo de equipo</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!--          Placa-->
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-barcode-scan
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.plaque || 'Sin placa' }}</v-list-item-title>
            <v-list-item-subtitle>Placa</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!--          Serial-->
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-barcode
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.serial }}</v-list-item-title>
            <v-list-item-subtitle>Serial</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!--          Marca-->

        <v-list-item v-if="hardware.brand">
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-registered-trademark
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.brand.name }}</v-list-item-title>
            <v-list-item-subtitle>Marca</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!--          Modelo-->

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-alpha-a-box
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.model || 'Sin modelo' }}</v-list-item-title>
            <v-list-item-subtitle>Modelo</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-cpu-64-bit
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.cpu || 'No disponible' }}</v-list-item-title>
            <v-list-item-subtitle>CPU</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-memory
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.memory || 'No disponible' }}</v-list-item-title>
            <v-list-item-subtitle>Memoria RAM</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-harddisk
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.hdd || 'No disponible' }}</v-list-item-title>
            <v-list-item-subtitle>Disco Duro</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!--          Fecha de compra-->

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-calendar-heart
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.purchaseDate || 'No disponible' }}</v-list-item-title>
            <v-list-item-subtitle>Fecha de compra</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hardware.assignee">
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-account-check
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ hardware.assignee.names }} {{ hardware.assignee.surnames }}
            </v-list-item-title>
            <v-list-item-subtitle>Asignado a</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="hardware.buyer">
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-briefcase-account
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.buyer.names }} {{ hardware.buyer.surnames }}</v-list-item-title>
            <v-list-item-subtitle>Comprado por</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-cash
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>COP ${{ formatNumber(hardware.cost) }}</v-list-item-title>
            <v-list-item-subtitle>Costo</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-laptop-off
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.damaged ? 'Si' : 'No' }}</v-list-item-title>
            <v-list-item-subtitle>Dado de baja</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="accent">
              mdi-basket
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ hardware.provider || 'No disponible' }}</v-list-item-title>
            <v-list-item-subtitle>Proveedor</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-col cols="12">
        <v-subheader>Accesorios registrados</v-subheader>
      </v-col>

      <v-col cols="12" v-if="hardware.accessories.length === 0">
        <v-alert type="warning">No hay ningún accesorio registrado</v-alert>
      </v-col>

      <v-col cols="12" v-else>
        <AccessoryItem :accessory="item"
                       :key="item._id"
                       v-for="item in hardware.accessories">
        </AccessoryItem>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import AccessoryItem from '@/components/AccessoryItem'
export default {
  components: { AccessoryItem },
  data: () => ({
    hardware: null,
    loading: false,
  }),
  methods: {
    formatNumber (number) {
      return new Intl.NumberFormat('es-ES').format(number)
    },
  },
  mounted() {
    if (!this.selectedHardware) return
    this.hardware = this.selectedHardware
  },
  props: ['selectedHardware'],
  watch: {
    selectedHardware (value) {
      if (!value) return
      this.hardware = value
    }
  }
}
</script>