<template>
  <v-container>
    <DeleteDialog @cancel="cancelDelete"
                  @delete="deleteItem"
                  :loading="loadingDelete"
                  v-model="showDeleteDialog">
    </DeleteDialog>

    <v-dialog v-model="showEditDialog" max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Editar accesorio
          </v-toolbar-title>

          <v-spacer/>

          <v-btn icon @click="closeEditDialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-text-field filled
                            v-model="editValue">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn @click="update"
                 :loading="loadingUpdate"
                 text>
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-text-field label="Ingresa o busca un accesorio"
                      hint="Presiona ENTER para agregar tu búsqueda como un nuevo accesorio"
                      :loading="loading || loadingCreate"
                      persistent-hint
                      filled
                      @keyup.enter="addNewAccessory"
                      v-model="newAccessory">
        </v-text-field>
      </v-col>

      <v-col cols="12" v-if="results.length === 0">
        <v-row align="center" justify="center" style="height: 75vh">
          <div class="text-center">
            <v-icon color="red"
                    size="80px">
              mdi-emoticon-sad
            </v-icon>

            <br>

            <span class="title" v-if="accessories.length === 0">
              Aun no has registrado un accesorio
            </span>

            <span class="title" v-else>
              No encontramos el accesorio que buscabas
            </span>

            <br>

            <span class="caption grey--text" v-if="accessories.length === 0">
              Empieza escribiendo algo en la caja de texto de arriba y presiona <strong>ENTER</strong> para agregar un nuevo accesorio
            </span>

            <span class="caption grey--text" v-else>
              Presiona <strong>ENTER</strong> si quieres agregar tu búsqueda como un nuevo accesorio
            </span>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <v-chip class="mr-4"
                @click="openEditDialog(index)"
                @click:close="openDeleteDialog(index)"
                :key="accessory._id"
                close
                large
                v-for="(accessory, index) in results">
          <span class="text-capitalize mx-3">
            {{accessory.name}}
          </span>
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import accessoriesService from '@/services/accessoriesService'
export default {
  components: { DeleteDialog },
  computed: {
    results () {
      if (this.newAccessory === '') return this.accessories

      return this.accessories.filter(item => {
        return item.name.toLowerCase().includes(this.newAccessory.toLowerCase())
      })
    }
  },
  data () {
    return {
      editValue: '',
      loading: false,
      loadingCreate: false,
      loadingDelete: false,
      loadingUpdate: false,
      accessories: [],
      newAccessory: '',
      selectedAccessoryToDelete: null,
      selectedAccessoryToEdit: null,
      showDeleteDialog: false,
      showEditDialog: false
    }
  },
  methods: {
    async addNewAccessory () {
      try {
        const exist = this.accessories.find(item => {
          return item.name.toLowerCase() === this.newAccessory.toLowerCase()
        })

        if (exist) return

        this.loadingCreate = true
        await accessoriesService.create(this.newAccessory)
        await this.listAccessories()
        this.newAccessory = ''
        this.loadingCreate = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingCreate = false
      }
    },
    cancelDelete () {
      this.showDeleteDialog = false
      this.selectedAccessoryToDelete = null
    },
    closeEditDialog () {
      this.showEditDialog = false
      this.selectedAccessoryToEdit = ''
    },
    async deleteItem () {
      try {
        this.loadingDelete = true
        await accessoriesService.remove(this.accessories[this.selectedAccessoryToDelete]._id)
        await this.listAccessories()
        this.selectedAccessoryToDelete = null
        this.showDeleteDialog = false
        this.loadingDelete = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingDelete = false
      }
    },
    async listAccessories () {
      try {
        this.loading = true
        const response = await accessoriesService.list()
        this.accessories = response.accessories
        this.loading = false
      } catch (e) {
        this.accessories = []
        this.$snotify.error(e.message)
        this.loading = false
      }
    },
    openDeleteDialog (index) {
      this.selectedAccessoryToDelete = index
      this.showDeleteDialog = true
    },
    openEditDialog (index) {
      this.selectedAccessoryToEdit = index
      this.editValue = this.accessories[index].name
      this.showEditDialog = true
    },
    async update () {
      try {
        this.loadingUpdate = true
        const data = {
          id: this.accessories[this.selectedAccessoryToEdit]._id,
          name: this.editValue
        }

        await accessoriesService.update(data)
        await this.listAccessories()
        this.selectedAccessoryToEdit = null
        this.showEditDialog = false
        this.loadingUpdate = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingUpdate = false
      }
    }
  },
  mounted () {
    this.listAccessories()
  }
}
</script>