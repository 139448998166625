<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" v-if="value.length === 0">
        <v-alert type="warning">No hay ningún accesorio registrado</v-alert>
      </v-col>

      <v-col cols="12">
        <v-row :key="`accessory ${index}`"
               v-for="(item, index) in value">
          <v-col cols="12" sm="6">
            <v-autocomplete :items="masterAccessories"
                            item-text="name"
                            item-value="name"
                            label="Nombre del accesorio"
                            outlined
                            v-model="item.name">
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6"></v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete :items="brands"
                            item-text="name"
                            item-value="name"
                            label="Marca"
                            outlined
                            v-model="item.brand">
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field label="Modelo"
                          outlined
                          v-model="item.model">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field label="Serial"
                          outlined
                          v-model="item.serial">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field label="Capacidad"
                          outlined
                          v-model="item.capacity">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field label="Velocidad"
                          outlined
                          v-model="item.speed">
            </v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field label="Fecha de compra"
                          outlined
                          v-model="item.purchaseDate">
            </v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea label="Notas adicionales"
                        outlined
                        v-model="item.notes">
            </v-textarea>
          </v-col>

          <v-col cols="12">
            <v-row justify="end" no-gutters>
              <v-btn @click="deleteAccessory(index)"
                     color="red"
                     dark>
                Eliminar accesorio
                <v-icon right>
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-btn block
               @click="addNewAccessory"
               color="accent"
               x-large>
          Agregar nuevo accesorio
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import accessoriesService from '@/services/accessoriesService'
import brandsService from '@/services/brandsService'

export default {
  data: () => ({
    accessory: {
      name: null,
      brand: null,
      model: null,
      serial: null,
      capacity: null,
      speed: null,
      purchaseDate: null,
      notes: null
    },
    brands: [],
    loadingAccessories: false,
    masterAccessories: [],
  }),
  methods: {
    addNewAccessory () {
      this.value.push({...this.accessory})
    },
    deleteAccessory (index) {
      this.value.splice(index, 1)
    },
    async listAccessories () {
      try {
        this.loadingAccessories = true
        const response = await accessoriesService.list()
        this.masterAccessories = response.accessories
        this.loadingAccessories = false
      } catch (e) {
        this.masterAccessories = []
        this.$snotify.error(e.message)
        this.loadingAccessories = false
      }
    },
    async listBrands () {
      try {
        this.loading = true
        const response = await brandsService.list()
        this.brands = response.brands
        this.loading = false
      } catch (e) {
        this.brands = []
        this.$snotify.error(e.message)
        this.loading = false
      }
    },
  },
  mounted () {
    this.listAccessories()
    this.listBrands()
  },
  props: ['value'],
  watch: {
    value (value) {
      if (value === []) {
        console.log('estoy aqui')
      }
    }
  }
}
</script>