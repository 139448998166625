<template>
  <v-container>
    <DeleteDialog @cancel="cancelDelete"
                  @delete="deleteItem"
                  :loading="loadingDelete"
                  v-model="showDeleteDialog">
    </DeleteDialog>

    <v-dialog v-model="showEditDialog" max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Editar tipo de mantenimiento
          </v-toolbar-title>

          <v-spacer/>

          <v-btn icon @click="closeEditDialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-text-field filled
                            v-model="editValue">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn @click="update"
                 :loading="loadingUpdate"
                 text>
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-text-field label="Ingresa o busca un tipo de mantenimiento"
                      hint="Presiona ENTER para agregar tu búsqueda como un nuevo tipo de mantenimiento"
                      :loading="loading || loadingCreate"
                      persistent-hint
                      filled
                      @keyup.enter="addNewMaintenanceType"
                      v-model="newMaintenanceType">
        </v-text-field>
      </v-col>

      <v-col cols="12" v-if="results.length === 0">
        <v-row align="center" justify="center" style="height: 75vh">
          <div class="text-center">
            <v-icon color="red"
                    size="80px">
              mdi-emoticon-sad
            </v-icon>

            <br>

            <span class="title" v-if="maintenanceTypes.length === 0">
              Aun no has registrado un tipo de mantenimiento
            </span>

            <span class="title" v-else>
              No encontramos la tipo de mantenimiento que buscabas
            </span>

            <br>

            <span class="caption grey--text" v-if="maintenanceTypes.length === 0">
              Empieza escribiendo algo en la caja de texto de arriba y presiona <strong>ENTER</strong> para agregar un nuevo tipo de mantenimiento
            </span>

            <span class="caption grey--text" v-else>
              Presiona <strong>ENTER</strong> si quieres agregar tu búsqueda como un nuevo tipo de mantenimiento
            </span>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <v-chip class="mr-4"
                @click="openEditDialog(index)"
                @click:close="openDeleteDialog(index)"
                :key="maintenanceType._id"
                close
                large
                v-for="(maintenanceType, index) in results">
          <span class="text-capitalize mx-3">
            {{maintenanceType.name}}
          </span>
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import maintenanceTypesService from '@/services/maintenanceTypesService'
export default {
  components: { DeleteDialog },
  computed: {
    results () {
      if (this.newMaintenanceType === '') return this.maintenanceTypes

      return this.maintenanceTypes.filter(item => {
        return item.name.toLowerCase().includes(this.newMaintenanceType.toLowerCase())
      })
    }
  },
  data () {
    return {
      editValue: '',
      loading: false,
      loadingCreate: false,
      loadingDelete: false,
      loadingUpdate: false,
      maintenanceTypes: [],
      newMaintenanceType: '',
      selectedMaintenanceTypeToDelete: null,
      selectedMaintenanceTypeToEdit: null,
      showDeleteDialog: false,
      showEditDialog: false
    }
  },
  methods: {
    async addNewMaintenanceType () {
      try {
        const exist = this.maintenanceTypes.find(item => {
          return item.name.toLowerCase() === this.newMaintenanceType.toLowerCase()
        })

        if (exist) return

        this.loadingCreate = true
        await maintenanceTypesService.create(this.newMaintenanceType)
        await this.listMaintenanceTypes()
        this.newMaintenanceType = ''
        this.loadingCreate = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingCreate = false
      }
    },
    cancelDelete () {
      this.showDeleteDialog = false
      this.selectedMaintenanceTypeToDelete = null
    },
    closeEditDialog () {
      this.showEditDialog = false
      this.selectedMaintenanceTypeToEdit = ''
    },
    async deleteItem () {
      try {
        this.loadingDelete = true
        await maintenanceTypesService.remove(this.maintenanceTypes[this.selectedMaintenanceTypeToDelete]._id)
        await this.listMaintenanceTypes()
        this.selectedMaintenanceTypeToDelete = null
        this.showDeleteDialog = false
        this.loadingDelete = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingDelete = false
      }
    },
    async listMaintenanceTypes () {
      try {
        this.loading = true
        const response = await maintenanceTypesService.list()
        this.maintenanceTypes = response.maintenanceTypes
        this.loading = false
      } catch (e) {
        this.maintenanceTypes = []
        this.$snotify.error(e.message)
        this.loading = false
      }
    },
    openDeleteDialog (index) {
      this.selectedMaintenanceTypeToDelete = index
      this.showDeleteDialog = true
    },
    openEditDialog (index) {
      this.selectedMaintenanceTypeToEdit = index
      this.editValue = this.maintenanceTypes[index].name
      this.showEditDialog = true
    },
    async update () {
      try {
        this.loadingUpdate = true
        const data = {
          id: this.maintenanceTypes[this.selectedMaintenanceTypeToEdit]._id,
          name: this.editValue
        }

        await maintenanceTypesService.update(data)
        await this.listMaintenanceTypes()
        this.selectedMaintenanceTypeToEdit = null
        this.showEditDialog = false
        this.loadingUpdate = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingUpdate = false
      }
    }
  },
  mounted () {
    this.listMaintenanceTypes()
  }
}
</script>