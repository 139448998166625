<template>
  <v-container>
    <DeleteDialog @cancel="cancelDelete"
                  @delete="deleteItem"
                  :loading="loadingDelete"
                  v-model="showDeleteDialog">
    </DeleteDialog>

    <NewEditEmployed @close="closeNewEditEmployed"
                     :selectedEmployee="selectedEmployeeToEdit"
                     @update="listEmployees"
                     v-model="showNewEditEmployee">
    </NewEditEmployed>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field filled
                          label="Buscar empleado"
                          append-icon="mdi-magnify">
              <template #append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="showNewEditEmployee = true"
                            color="primary"
                            v-bind="attrs"
                            v-on="on">
                      mdi-plus
                    </v-icon>
                  </template>

                  <span>Agregar empleado</span>
                </v-tooltip>

              </template>
            </v-text-field>

          </v-card-title>

          <v-data-table :headers="headers"
                        :items="employees"
                        :items-per-page="10">
            <template v-slot:item.actions="{ item }">
              <v-icon @click="updateItem(item)">
                mdi-pencil
              </v-icon>

              <v-icon class="ml-2"
                      @click="openDeleteDialog(item)"
                      color="red">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import employeesService from '@/services/employeesService'
import NewEditEmployed from './NewEditEmploye'
export default {
  components: { DeleteDialog, NewEditEmployed },
  data () {
    return {
      headers: [
        { value: 'actions', align: 'center' },
        { text: 'Nombres', value: 'names' },
        { text: 'Apellidos', value: 'surnames' },
        { text: 'Área de la empresa', value: 'area.name' },
        { text: 'Cargo', value: 'position' },
      ],
      employees: [],
      loading: false,
      loadingDelete: false,
      selectedEmployeeToDelete: null,
      selectedEmployeeToEdit: null,
      showDeleteDialog: false,
      showNewEditEmployee: false
    }
  },
  methods: {
    cancelDelete () {
      this.showDeleteDialog = false
      this.selectedEmployeeToDelete = null
    },
    closeNewEditEmployed () {
      this.selectedEmployeeToEdit = null
      this.showNewEditEmployee = false
    },
    async deleteItem () {
      try {
        this.loadingDelete = true
        await employeesService.remove(this.selectedEmployeeToDelete._id)
        await this.listEmployees()
        this.selectedEmployeeToDelete = null
        this.showDeleteDialog = false
        this.loadingDelete = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingDelete = false
      }
    },
    async listEmployees () {
      try {
        this.loading = true
        const response = await employeesService.list()
        this.employees = response.employees
        this.loading = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loading = false
      }
    },
    openDeleteDialog (item) {
      this.selectedEmployeeToDelete = item
      this.showDeleteDialog = true
    },
    updateItem (item) {
      this.selectedEmployeeToEdit = item
      this.showNewEditEmployee = true
    }
  },
  mounted () {
    this.listEmployees()
  }
}
</script>