import axios from 'axios'

const hardwareService = {}

hardwareService.create = async data => {
    let { plaque, serial, hardwareType, brand, model, cpu, memory, hdd, assignee, buyer, cost, provider, purchaseDate, damaged, observations, accessories } = data

    if (!serial ) throw new Error('El serial es necesario')

    const request = {
        method: 'post',
        url: '/hardware',
        data: { plaque, serial, hardwareType, brand, model, cpu, memory, hdd, assignee, buyer, cost, provider, purchaseDate, damaged, observations, accessories }
    }

    const response = await axios(request)
    return response.data
}

hardwareService.remove = async id => {
    if (!id) return

    const request = {
        method: 'delete',
        url: `/hardware/${id}`
    }

    const response = await axios(request)
    return response.data
}

hardwareService.list = async () => {
    const request = {
        method: 'get',
        url: '/hardware',
    }

    const response = await axios(request)
    return response.data
}

hardwareService.update = async data => {
    let { id, plaque, serial, hardwareType, brand, model, cpu, memory, hdd, assignee, buyer, cost, provider, purchaseDate, damaged, observations, accessories } = data

    if (!plaque || !serial ) return

    const request = {
        method: 'put',
        url: `/hardware/${id}`,
        data: { plaque, serial, hardwareType, brand, model, cpu, memory, hdd, assignee, buyer, cost, provider, purchaseDate, damaged, observations, accessories }
    }

    request.data.accessories.forEach(item => {
        delete item._id
    })

    const response = await axios(request)
    return response.data
}

hardwareService.getById = async id => {
    if (!id) return

    const request = {
        method: 'get',
        url: `/hardware/${id}`
    }

    const response = await axios(request)
    return response.data
}

export default hardwareService