import axios from 'axios'

const accessoriesService = {}

accessoriesService.create = async name => {
    if (!name) return

    const request = {
        method: 'post',
        url: '/accessories',
        data: { name }
    }

    const response = await axios(request)
    return response.data
}

accessoriesService.remove = async id => {
    if (!id) return

    const request = {
        method: 'delete',
        url: `/accessories/${id}`
    }

    const response = await axios(request)
    return response.data
}

accessoriesService.list = async () => {
    const request = {
        method: 'get',
        url: '/accessories',
    }

    const response = await axios(request)
    return response.data
}

accessoriesService.update = async data => {
    const { id, name } = data

    const request = {
        method: 'put',
        url: `/accessories/${id}`,
        data: { name }
    }

    const response = await axios(request)
    return response.data
}

export default accessoriesService