<template>
  <v-dialog max-width="500px"
            persistent
            @input="$emit('input')"
            :value="value">
    <v-card color="red" dark>
      <v-toolbar color="red" flat>
        <v-toolbar-title>
          Confirmar
        </v-toolbar-title>

        <v-spacer/>

        <v-btn @click="$emit('cancel')"
               icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-center white--text">
            <span class="title">¿Esta seguro que desea eliminar este elemento?</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn @click="$emit('cancel')"
               text>
          Cancelar
        </v-btn>

        <v-btn @click="$emit('delete')"
               :loading="loading"
               outlined>
          Borrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['loading', 'value']
}
</script>