import axios from 'axios'

const maintenanceTypesService = {}

maintenanceTypesService.create = async name => {
    if (!name) return

    const request = {
        method: 'post',
        url: '/maintenance-types',
        data: { name }
    }

    const response = await axios(request)
    return response.data
}

maintenanceTypesService.remove = async id => {
    if (!id) return

    const request = {
        method: 'delete',
        url: `/maintenance-types/${id}`
    }

    const response = await axios(request)
    return response.data
}

maintenanceTypesService.list = async () => {
    const request = {
        method: 'get',
        url: '/maintenance-types',
    }

    const response = await axios(request)
    return response.data
}

maintenanceTypesService.update = async data => {
    const { id, name } = data

    const request = {
        method: 'put',
        url: `/maintenance-types/${id}`,
        data: { name }
    }

    const response = await axios(request)
    return response.data
}

export default maintenanceTypesService