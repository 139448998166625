const state = {
    token: ''
}

const mutations = {
    LOGOUT (state) {
        state.token = ''
    },
    SET_TOKEN (state, value) {
        state.token = value
    }
}

const actions = {}

const getters = {
    isLoggedIn (state) {
        return !!state.token
    }
}

export default { actions, getters, mutations, state }