<template>
  <v-navigation-drawer app>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          Bienvenido
        </v-list-item-title>
        <v-list-item-subtitle>
          <Clock/>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider/>

    <v-list dense
            nav>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon>
              mdi-view-dashboard
            </v-icon>
          </v-list-item-icon>

          <v-list-item-title>Maestros</v-list-item-title>
        </template>

        <v-list-item v-for="item in itemsMaestros"
                     :key="item.title"
                     link
                     @click="goto(item.path)">
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item v-for="item in items"
                   :key="item.title"
                   link
                   :to="item.path">
        <v-list-item-icon>
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Clock from './Clock';

export default {
  components: {
    Clock
  },
  data: () => ({
    items: [
      { title: 'Employees', path: '/empleados', icon: 'mdi-account-group' },
      { title: 'Equipos', path: '/equipos', icon: 'mdi-desktop-classic' },
      { title: 'Mantenimiento', path: '/mantenimiento', icon: 'mdi-wrench' }
    ],
    itemsMaestros: [
      { title: 'Marcas', path: '/marcas', icon: 'mdi-apple' },
      { title: 'Tipos de equipos', path: '/tipos-de-equipos', icon: 'mdi-laptop' },
      { title: 'Tipos de mantenimiento', path: '/tipos-de-mantenimiento', icon: 'mdi-progress-wrench' },
      { title: 'Accesorios', path: '/accesorios', icon: 'mdi-mouse' },
      { title: 'Areas de la empresa', path: '/areas-de-la-empresa', icon: 'mdi-domain' },
    ],
  }),
  methods: {
    goto (path) {
      this.$router.push(path)
    }
  }
}
</script>