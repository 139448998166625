<template>
  <v-dialog max-width="500px"
            :value="value"
            @input="$emit('input', $event)">
    <v-card>
      <v-toolbar color="#F56552" dark flat>
        <v-toolbar-title>
          {{ selectedEmployee ? 'Editar empleado' : 'Agregar nuevo empleado' }}
        </v-toolbar-title>

        <v-spacer/>

        <v-btn @click="close" icon>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-4">
        <v-form lazy-validation
                ref="newEditEmployedForm"
                v-model="valid">
          <v-row dense>
            <v-col cols="12">
              <v-text-field label="Nombres"
                            outlined
                            :rules="[rules.required]"
                            v-model="employee.names">
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Apellidos"
                            outlined
                            :rules="[rules.required]"
                            v-model="employee.surnames">
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete :items="areas"
                              item-text="name"
                              item-value="_id"
                              label="Área de la empresa"
                              outlined
                              v-model="employee.area">
              </v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-text-field label="Cargo"
                            outlined
                            v-model="employee.position">
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn @click="close"
               color="red"
               text>
          Cancelar
        </v-btn>

        <v-btn @click="submit"
               color="accent"
               dark
               :loading="loadingCreate">
          {{ selectedEmployee ? 'Actualizar' : 'Crear' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/libs/rules'
import areasService from '@/services/areasService'
import employeesService from '@/services/employeesService';
export default {
  async created () {
    try {
      this.loadingAreas = true
      const response = await areasService.list()
      this.areas = response.areas
      this.loadingAreas = false
    } catch (e) {
      this.$snotify.error(e.message)
      this.loadingAreas = false
    }
  },
  data: () => ({
    areas: [],
    employee: {
      names: '',
      surnames: '',
      area: '',
      position: '',
      picture: ''
    },
    loadingAreas: false,
    loadingCreate: false,
    rules,
    valid: true
  }),
  methods: {
    close () {
      this.$refs.newEditEmployedForm.reset()
      this.$emit('close')
    },
    async create () {
      const data = {
        names: this.employee.names,
        surnames: this.employee.surnames,
        area: this.employee.area,
        position: this.employee.position
      }

      await employeesService.create(data)
    },
    async submit () {
      try {
        if (!this.$refs.newEditEmployedForm.validate()) return
        this.loadingCreate = true

        this.selectedEmployee ? await this.update() : await this.create()

        this.loadingCreate = false
        this.$emit('update')
        this.close()
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingCreate = false
      }
    },
    async update () {
      const data = {
        id: this.selectedEmployee._id,
        names: this.employee.names,
        surnames: this.employee.surnames,
        area: this.employee.area,
        position: this.employee.position
      }

      await employeesService.update(data)
    }
  },
  props: ['selectedEmployee', 'value'],
  watch: {
    selectedEmployee (value) {
      if (value) {
        this.employee = {
          names: value.names,
          surnames: value.surnames,
          area: value.area._id,
          position: value.position,
          picture: value.picture
        }
      }
    }
  }
}
</script>