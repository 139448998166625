<template>
  <v-container>
    <DeleteDialog @cancel="cancelDelete"
                  @delete="deleteItem"
                  :loading="loadingDelete"
                  v-model="showDeleteDialog">
    </DeleteDialog>

    <v-dialog v-model="showEditDialog" max-width="500px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Editar marca
          </v-toolbar-title>

          <v-spacer/>

          <v-btn icon @click="closeEditDialog">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row>
            <v-col cols="12" class="mt-3">
              <v-text-field filled
                            v-model="editValue">
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>

          <v-btn @click="update"
                 :loading="loadingUpdate"
                 text>
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-text-field label="Ingresa o busca una marca"
                      hint="Presiona ENTER para agregar tu búsqueda como una nueva marca"
                      :loading="loading || loadingCreate"
                      persistent-hint
                      filled
                      @keyup.enter="addNewBrand"
                      v-model="newBrand">
        </v-text-field>
      </v-col>

      <v-col cols="12" v-if="results.length === 0">
        <v-row align="center" justify="center" style="height: 75vh">
          <div class="text-center">
            <v-icon color="red"
                    size="80px">
              mdi-emoticon-sad
            </v-icon>

            <br>

            <span class="title" v-if="brands.length === 0">
              Aun no has registrado una marca
            </span>

            <span class="title" v-else>
              No encontramos la marca que buscabas
            </span>

            <br>

            <span class="caption grey--text" v-if="brands.length === 0">
              Empieza escribiendo algo en la caja de texto de arriba y presiona <strong>ENTER</strong> para agregar una nueva marca
            </span>

            <span class="caption grey--text" v-else>
              Presiona <strong>ENTER</strong> si quieres agregar tu búsqueda como una nueva marca
            </span>
          </div>
        </v-row>
      </v-col>

      <v-col cols="12" v-else>
        <v-chip class="mr-4"
                @click="openEditDialog(index)"
                @click:close="openDeleteDialog(index)"
                :key="mark._id"
                close
                large
                v-for="(mark, index) in results">
          <span class="text-capitalize mx-3">
            {{mark.name}}
          </span>
        </v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import brandsService from '@/services/brandsService'
export default {
  components: { DeleteDialog },
  computed: {
    results () {
      if (this.newBrand === '') return this.brands

      return this.brands.filter(item => {
        return item.name.toLowerCase().includes(this.newBrand.toLowerCase())
      })
    }
  },
  data () {
    return {
      editValue: '',
      loading: false,
      loadingCreate: false,
      loadingDelete: false,
      loadingUpdate: false,
      brands: [],
      newBrand: '',
      selectedBrandToDelete: null,
      selectedBrandToEdit: null,
      showDeleteDialog: false,
      showEditDialog: false
    }
  },
  methods: {
    async addNewBrand () {
      try {
        const exist = this.brands.find(item => {
          return item.name.toLowerCase() === this.newBrand.toLowerCase()
        })

        if (exist) return

        this.loadingCreate = true
        await brandsService.create(this.newBrand)
        this.newBrand = ''
        await this.listBrands()
        this.loadingCreate = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingCreate = false
      }
    },
    cancelDelete () {
      this.showDeleteDialog = false
      this.selectedBrandToDelete = null
    },
    closeEditDialog () {
      this.showEditDialog = false
      this.selectedBrandToEdit = ''
    },
    async deleteItem () {
      try {
        this.loadingDelete = true
        await brandsService.remove(this.brands[this.selectedBrandToDelete]._id)
        await this.listBrands()
        this.selectedBrandToDelete = null
        this.showDeleteDialog = false
        this.loadingDelete = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingDelete = false
      }
    },
    async listBrands () {
      try {
        this.loading = true
        const response = await brandsService.list()
        this.brands = response.brands
        this.loading = false
      } catch (e) {
        this.brands = []
        this.$snotify.error(e.message)
        this.loading = false
      }
    },
    openDeleteDialog (index) {
      this.selectedBrandToDelete = index
      this.showDeleteDialog = true
    },
    openEditDialog (index) {
      this.selectedBrandToEdit = index
      this.editValue = this.brands[index].name
      this.showEditDialog = true
    },
    async update () {
      try {
        this.loadingUpdate = true
        const data = {
          id: this.brands[this.selectedBrandToEdit]._id,
          name: this.editValue
        }

        await brandsService.update(data)
        await this.listBrands()
        this.selectedBrandToEdit = null
        this.showEditDialog = false
        this.loadingUpdate = false
      } catch (e) {
        this.$snotify.error(e.message)
        this.loadingUpdate = false
      }
    }
  },
  mounted () {
    this.listBrands()
  }
}
</script>