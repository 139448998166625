<template>
  <v-container>
    <ReportMaintenance @update="listMaintenances"
                       v-model="showReportMaintenance">
    </ReportMaintenance>

    <ViewMaintenance @close="closeViewMaintenance"
                     :selectedMaintenance="selectedItemToView"
                     v-model="viewMaintenance"
                     :showHardwareSection="true">
    </ViewMaintenance>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-text-field filled
                          label="Buscar mantenimiento"
                          append-icon="mdi-magnify">
              <template #append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon @click="showReportMaintenance = true"
                            color="primary"
                            v-bind="attrs"
                            v-on="on">
                      mdi-plus
                    </v-icon>
                  </template>

                  <span>Reportar mantenimiento</span>
                </v-tooltip>

              </template>
            </v-text-field>
          </v-card-title>

          <v-data-table :headers="headers"
                        :items="maintenances"
                        :items-per-page="10">
            <template v-slot:item.serial="{ item }">
              <span>{{ item.hardware[0].serial }}</span>
            </template>

            <template v-slot:item.assignee="{ item }">
              <span>{{ item.assignee[0].firstName }} {{ item.assignee[0].lastName }}</span>
            </template>

            <template v-slot:item.maintenanceType="{ item }">
              <span>{{ item.maintenanceType[0].name }}</span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-icon @click="viewItem(item)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import maintenancesService from '@/services/maintenancesService'
import ReportMaintenance from '@/pages/Maintenances/ReportMaintenance'
import ViewMaintenance from '@/pages/Maintenances/ViewMaintenance'
export default {
  components: { ReportMaintenance, ViewMaintenance },
  data () {
    return {
      maintenances: [],
      headers: [
        { value: 'actions', align: 'center', sortable: false },
        { text: 'Serial', value: 'hardware.serial' },
        { text: 'Realizado por', value: 'madeBy.names' },
        { text: 'Tipo de mantenimiento', value: 'maintenanceType.name' },
        { text: 'Fecha', value: 'dateStarts' },
        { text: 'Hora', value: 'dateEnds' },
        { text: 'Motivo', value: 'reason' },
      ],
      selectedItemToView: false,
      showReportMaintenance: false,
      viewMaintenance: false
    }
  },
  methods: {
    closeViewMaintenance () {
      this.selectedItemToView = null
      this.viewMaintenance = false
    },
    async listMaintenances () {
      try {
        const response = await maintenancesService.list()
        this.maintenances = response.maintenances
      } catch (e) {
        this.$snotify.error(e.message)
      }
    },
    viewItem (item) {
      this.selectedItemToView = item
      this.viewMaintenance = true
    }
  },
  mounted () {
    this.listMaintenances()
  }
}
</script>