<template>
  <v-app>
    <v-app-bar app
               color="primary"
               dark>
      <v-app-bar-nav-icon>
        <v-img src="../assets/logo.png" max-width="40px"></v-img>
      </v-app-bar-nav-icon>

      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>

      <v-spacer/>

      <v-btn @click="logout" text>
        Salir
      </v-btn>
    </v-app-bar>

    <Sidebar/>

    <v-main>
      <transition mode="out-in"
                  name="fade">
        <router-view style="animation-duration: 0.3s"/>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from '../components/Sidebar'
export default {
  components: {
    Sidebar
  },
  methods: {
    logout () {
      this.$store.commit('LOGOUT')
    }
  }
}
</script>